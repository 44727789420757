import React, { useRef } from 'react';
import Slider from 'react-slick';
import { SectionTitle } from '../../../shared/components';
import styles from './TeamSection.module.scss';

export const TeamSection = () => {
  const prevDotIndexRef = useRef(0);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },

      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    appendDots: dots => {
      let selectedDot = '';

      dots.forEach((dot, i) => {
        if (dot.props.className) {
          if (i > prevDotIndexRef.current) {
            selectedDot = `forward-${i}`;
          }
          prevDotIndexRef.current = i;
        }
      });

      return (
        <div>
          <ul className={`${styles.dotsList} ${styles[selectedDot]}`}> {dots} </ul>
        </div>
      );
    },
  };

  return (
    <section id='team' className={styles.teamSection}>
      <div className={styles.teamContainer}>
        <div className={styles.teamText}>
          <SectionTitle title='Team' />
        </div>
        <Slider {...settings}>
          <div className={styles.teamItemContainer}>
            <div className={styles.teamItem}>
              <div className={styles.photo}>
                <img
                  className={styles.photoImg}
                  src={process.env.PUBLIC_URL + '/images/av-yevgen.png'}
                  alt='Yevgen Lopatin'
                />
              </div>
              <div className={styles.info}></div>
              <h6 className={styles.name}>Yevgen Lopatin</h6>
              <div className={styles.social}>
                <a
                  href='https://www.linkedin.com/in/yevgen-lopatin-41117519/'
                  target='_blank'
                  className={styles.linkedin}
                  rel='noopener noreferrer'
                >
                  <img src={process.env.PUBLIC_URL + '/images/s-linkedin.svg'} alt='Linkedin link' />
                </a>
              </div>
              <div className={styles.descriptionContainer}>
                <ul className={styles.description}>
                  <li>
                    Yevhen has spent his career fueling creative startups. As a corporate consultant in telecom, IT and
                    biotech, he has transformed multiple young companies into high-performance growth entities.
                  </li>
                  <li>Co-founder of several technological and art businesses</li>
                  <li>20+ years of corporate management experience </li>
                  <li>5+ years of gaming industry experience</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.teamItemContainer}>
            <div className={styles.teamItem}>
              <div className={styles.photo}>
                <img
                  className={styles.photoImg}
                  src={process.env.PUBLIC_URL + '/images/av-iurii.png'}
                  alt='Iurii Usov'
                />
              </div>
              <div className={styles.info}></div>
              <h6 className={styles.name}>Iurii Usov</h6>
              <div className={styles.social}>
                <a
                  href='https://www.linkedin.com/in/iurii-usov-282386188/'
                  target='_blank'
                  className={styles.linkedin}
                  rel='noopener noreferrer'
                >
                  <img src={process.env.PUBLIC_URL + '/images/s-linkedin.svg'} alt='Linkedin link' />
                </a>
              </div>
              <div className={styles.descriptionContainer}>
                <ul className={styles.description}>
                  <li>
                    Iurii&#39;s extensive background experience in corporate sales has helped him build several
                    successful businesses in artificial intelligence, art and gaming from the ground up.
                  </li>
                  <li>Co-founder of several technological and art businesses</li>
                  <li>20+ years of corporate management experience </li>
                  <li>7+ years of gaming industry experience</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.teamItemContainer}>
            <div className={styles.teamItem}>
              <div className={styles.photo}>
                <img
                  className={styles.photoImg}
                  src={process.env.PUBLIC_URL + '/images/av-victor.png'}
                  alt='Victor Khozeyev'
                />
              </div>
              <div className={styles.info}></div>
              <h6 className={styles.name}>Victor Khozeyev</h6>
              <div className={styles.social}>
                <a
                  href='https://www.linkedin.com/in/viktor-khozeyev-663315264/'
                  target='_blank'
                  className={styles.linkedin}
                  rel='noopener noreferrer'
                >
                  <img src={process.env.PUBLIC_URL + '/images/s-linkedin.svg'} alt='Linkedin link' />
                </a>
              </div>
              <div className={styles.descriptionContainer}>
                <ul className={styles.description}>
                  <li>
                    Victor is a financial manager with 20+ years of experience in the corporate, banking and individual
                    sectors. MBA graduate
                  </li>
                  <li>20+ years of financial management and accounting expertise</li>
                  <li> 7+ years of experience as an CFO</li>
                  <li> 6+ years of experience in project management</li>
                </ul>
              </div>
            </div>
          </div>

          <div className={styles.teamItemContainer}>
            <div className={styles.teamItem}>
              <div className={styles.photo}>
                <img
                  className={styles.photoImg}
                  src={process.env.PUBLIC_URL + '/images/av-vitalii.png'}
                  alt='Vitalii Yatskiv'
                />
              </div>
              <div className={styles.info}></div>
              <h6 className={styles.name}>Vitalii Yatskiv</h6>
              <div className={styles.social}>
                <a
                  href='https://www.linkedin.com/in/vitalii-yatskiv/'
                  target='_blank'
                  className={styles.linkedin}
                  rel='noopener noreferrer'
                >
                  <img src={process.env.PUBLIC_URL + '/images/s-linkedin.svg'} alt='Linkedin link' />
                </a>
              </div>
              <div className={styles.descriptionContainer}>
                <ul className={styles.description}>
                  <li>
                    Vitalii is a Web3 enthusiast. He is a graduate of the Lviv Business School Alumni, Master in
                    Innovation and Entrepreneurship.
                  </li>
                  <li>The pioneer of blockchain gaming </li>
                  <li> 5+ years of experience in blockchain development </li>
                  <li>He has developed the first cross chain NFT game Dark Country, based on WAX blockchain</li>
                </ul>
              </div>
            </div>
          </div>
          <div className={styles.teamItemContainer}>
            <div className={styles.teamItem}>
              <div className={styles.photo}>
                <img
                  className={styles.photoImg}
                  src={process.env.PUBLIC_URL + '/images/av-nina.png'}
                  alt='Nina Zamidra'
                />
              </div>
              <div className={styles.info}></div>
              <h6 className={styles.name}>Nina Zamidra</h6>
              <div className={styles.social}>
                <a
                  href='https://www.linkedin.com/in/nina-zamidra/'
                  target='_blank'
                  className={styles.linkedin}
                  rel='noopener noreferrer'
                >
                  <img src={process.env.PUBLIC_URL + '/images/s-linkedin.svg'} alt='Linkedin link' />
                </a>
              </div>
              <div className={styles.descriptionContainer}>
                <ul className={styles.description}>
                  <li>
                    Nina is a master multitasker and has the unique ability to make the highly complex look effortlessly
                    simple.
                  </li>
                  <li>5+ years of experience in project management </li>
                  <li>Facilitates communication within projects </li>
                  <li>Winner, CIMA Ukraine Business Challenge</li>
                  <li>Enactus World Cup, London</li>
                </ul>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};
