import React, { useState } from 'react';
import styles from './ContactsSection.module.scss';

export const ContactsSection = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const onChangeName = event => {
    setName(event.target.value);
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangeMessage = event => {
    setMessage(event.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();

    fetch('https://2m4cwt4durztw2ccm4mbcxtvp40nyuxl.lambda-url.us-east-1.on.aws', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message }),
    });
    setName('');
    setEmail('');
    setMessage('');
  };

  return (
    <section id='contacts' className={styles.contactsSection}>
      <div className={styles.contactsContainer}>
        <div className={styles.contactsText}>
          <h2 className={styles.sectionTitle}>Contacts</h2>
        </div>
        <div className={styles.formContainer}>
          <form
            onSubmit={e => {
              handleSubmit(e);
            }}
            className={styles.form}
          >
            <input
              className={`${styles.input} ${styles.inputName}`}
              value={name}
              onChange={onChangeName}
              id='name'
              type='text'
              name='name'
              placeholder='Name'
            ></input>
            <input
              className={`${styles.input} ${styles.inputEmail}`}
              value={email}
              onChange={onChangeEmail}
              id='email'
              type='email'
              name='email'
              placeholder='Email'
            ></input>
            <input
              className={`${styles.input} ${styles.inputMessage}`}
              value={message}
              onChange={onChangeMessage}
              id='message'
              type='text'
              name='message'
              placeholder='Message'
            ></input>
            <div className={styles.buttonWrapper}>
              <button type='submit' className={styles.button}>
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
