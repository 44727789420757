import React from 'react';
import { AboutSection } from './AboutSection';
import { BannerSection } from './BannerSection';
import { ServicesSection } from './ServicesSection';
import { RoadmapSection } from './RoadmapSection';
import { TeamSection } from './TeamSection';
import { ContactsSection } from './ContactsSection';
import styles from './Main.module.scss';

export const Main = () => {
  return (
    <main className={styles.main}>
      <BannerSection />
      <div id='about' className={styles.mainBackground}>
        <div className={styles.tickerContainer}>
          <div className={styles.ticker}></div>
        </div>
        <AboutSection />
        <ServicesSection />
        <RoadmapSection />
        <TeamSection />
        <ContactsSection />
      </div>
    </main>
  );
};
