import React from 'react';
import { SectionTitle } from '../../../shared/components';
import styles from './RoadmapSection.module.scss';

export const RoadmapSection = () => {
  return (
    <section id='roadmap' className={styles.roadmapSection}>
      <div className={styles.roadmapContainer}>
        <SectionTitle title='Roadmap' />
        <div className={styles.roadmapPoints}>
          <div className={styles.roadmapPointContainer}>
            <div className={styles.roadmapPoint}>
              <span className={`${styles.pointIcon} ${styles.first}`}></span>
              <h5 className={styles.pointTitle}>Q4 2022</h5>
            </div>
            <div className={styles.roadmapPointDescription}>
              <ul className={`${styles.descriptionList} ${styles.first}`}>
                <li>Landing page launch</li>
                <li>Testnet Smart contracts</li>
                <li>Unity plugin (alpha version)</li>
                <li>Start to build community</li>
                <li>Seed investment round</li>
              </ul>
            </div>
          </div>
          <div className={styles.roadmapPointContainer}>
            <div className={styles.roadmapPoint}>
              <span className={`${styles.pointIcon} ${styles.second}`}></span>
              <h5 className={styles.pointTitle}>Q1 2023</h5>
            </div>
            <div className={styles.roadmapPointDescription}>
              <ul className={`${styles.descriptionList} ${styles.second}`}>
                <li>Management application launch</li>
                <li>Mainnet Smart contracts</li>
                <li>Unity plugin release</li>
                <li>Unreal Engine plugin release</li>
                <li>OpenHarbor v1 protocol launch</li>
                <li>First game partner</li>
              </ul>
            </div>
          </div>
          <div className={styles.roadmapPointContainer}>
            <div className={styles.roadmapPoint}>
              <span className={`${styles.pointIcon} ${styles.third}`}></span>
              <h5 className={styles.pointTitle}>Q2 2023</h5>
            </div>
            <div className={styles.roadmapPointDescription}>
              <ul className={`${styles.descriptionList} ${styles.third}`}>
                <li>Internal marketplace</li>
                <li>NFT licence rent</li>
                <li>Private investment round</li>
                <li>Preparation for token listing</li>
              </ul>
            </div>
          </div>
          <div className={styles.roadmapPointContainer}>
            <div className={styles.roadmapPoint}>
              <span className={`${styles.pointIcon} ${styles.forth}`}></span>
              <h5 className={styles.pointTitle}>Q3 2023</h5>
            </div>
            <div className={styles.roadmapPointDescription}>
              <ul className={`${styles.descriptionList} ${styles.forth}`}>
                <li>Token listing</li>
                <li>AAA game partnership</li>
                <li>Integration achievements to license</li>
                <li>Plugins for new game engines</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
