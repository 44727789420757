import React from 'react';
import { SectionTitle } from '../../../shared/components';
import styles from './AboutSection.module.scss';

export const AboutSection = () => {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.aboutContainer}>
        <div className={styles.aboutText}>
          <SectionTitle title='About' />
          <p className={styles.aboutDescription}>
            Web 3 service for creating, integrating and selling game licenses. We make a world where the player truly
            owns his assets. We are changing the stuck rules of Web 2 game licenses, where the player cannot dispose of
            what he bought. We are the future of gaming.
          </p>
        </div>
        <div className={styles.aboutSteps}>
          <div className={styles.stepContainer}>
            <div className={`${styles.step} ${styles.buyStep}`}>
              <h4 className={styles.stepTitle}>1.Buy NFT license</h4>
            </div>
          </div>
          <div className={styles.stepContainer}>
            <div className={`${styles.step} ${styles.playStep}`}>
              <h4 className={styles.stepTitle}>2.Play the game</h4>
            </div>
          </div>
          <div className={styles.stepContainer}>
            <div className={`${styles.step} ${styles.joinStep}`}>
              <h4 className={styles.stepTitle}>3.Join Web3 gaming future</h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
