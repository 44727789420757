import React from 'react';
import styles from './Footer.module.scss';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerBg}>
        <div className={styles.footerContainer}>
          <div className={styles.footerText}>All rights reserved by OpenHarbor</div>
          <ul className={styles.footerSocialsList}>
            <li className={styles.footerSocialsListItem}>
              <a
                href='https://t.me/open_harbor'
                target='_blank'
                className={`${styles.footerSocialsItemLink} ${styles.telegram} `}
                rel='noopener noreferrer'
              ></a>
            </li>
            <li className={styles.footerSocialsListItem}>
              <a
                href='https://twitter.com/Openharbor_ai'
                target='_blank'
                className={`${styles.footerSocialsItemLink} ${styles.twitter} `}
                rel='noopener noreferrer'
              ></a>
            </li>
            <li className={styles.footerSocialsListItem}>
              <a
                href='https://discord.gg/zYsxsQdswd'
                target='_blank'
                className={`${styles.footerSocialsItemLink} ${styles.discord} `}
                rel='noopener noreferrer'
              ></a>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
