import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './SubscribePage.module.scss';

export const SubscribePage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [isAgree, setIsAgree] = useState(false);

  const onChangeName = event => {
    setName(event.target.value);
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
  };

  const onChangeAgreement = event => {
    setIsAgree(event.target.checked);
  };

  const handleSubmit = e => {
    e.preventDefault();

    console.log(name, email, String(isAgree));

    fetch('https://2m4cwt4durztw2ccm4mbcxtvp40nyuxl.lambda-url.us-east-1.on.aws', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message: 'Subscription' }),
    });
    setName('');
    setEmail('');
    setIsAgree(false);
  };

  return (
    <div className={styles.subscribePageSection}>
      <div className={styles.subscribePageContainer}>
        <div className={styles.subscribePageHeader}>
          {' '}
          <Link to='/' className={styles.logoLink}>
            <img src={process.env.PUBLIC_URL + '/images/header-logo.svg'} alt='Logo' className={styles.logoImg} />
          </Link>
        </div>
        <div className={styles.infoFormContainer}>
          <div className={styles.infoFormWrapper}>
            <div className={styles.formContainer}>
              <form
                onSubmit={e => {
                  handleSubmit(e);
                }}
                className={styles.form}
              >
                <input
                  className={`${styles.input} ${styles.inputName}`}
                  value={name}
                  onChange={onChangeName}
                  id='name'
                  type='text'
                  name='name'
                  placeholder='Name'
                ></input>
                <input
                  className={`${styles.input} ${styles.inputEmail}`}
                  value={email}
                  onChange={onChangeEmail}
                  id='email'
                  type='email'
                  name='email'
                  placeholder='Email'
                ></input>
                <div className={styles.checkboxWrapper}>
                  <input
                    className={`${styles.checkbox} ${styles.inputMessage}`}
                    value={isAgree}
                    onChange={onChangeAgreement}
                    id='isAgree'
                    type='checkbox'
                    name='isAgree'
                    checked={isAgree}
                  ></input>
                  <label htmlFor='isAgree' className={styles.checkboxLabel}></label>
                  <p className={styles.checkboxInfo}>
                    You confirm that you&#39;ve read and agree to the{' '}
                    <a
                      href={process.env.PUBLIC_URL + '/documents/Terms_and_conditions.pdf'}
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms and Conditions and Privacy Policy
                    </a>{' '}
                    of the company and confirm that you are of legal age.
                  </p>
                </div>
                <div className={styles.buttonWrapper}>
                  <button type='submit' className={styles.button} disabled={!isAgree}>
                    Subscribe
                  </button>
                </div>
              </form>
            </div>
            <div className={styles.infoWrapper}>
              <div className={styles.infoItemWrapper}>
                <h2 className={styles.descriptionHeader}>For gamers</h2>
                <ul className={styles.description}>
                  <li>
                    Fresh ways to stack your money, show off your gaming skills, and grow your crew as a gaming guru
                  </li>
                  <li>
                    Rep your favorite games, whether they&#39;re mainstream hits or underground gems, as an official
                    ambassador
                  </li>
                  <li>Build and lead your own posse of partners and sub-partners in the biz</li>
                  <li>
                    Turn your homies and followers into your own mini (or massive) distribution network for game
                    licenses
                  </li>
                  <li>Score early access to OH tokens and a spot in the OH DAO</li>
                  <li>
                    A sick biz model for re-selling tokenized game licenses, all powered by Open Harbor Bridge tech
                  </li>
                  <li>
                    A multi-tiered affiliate program that lets you rep your fave game releases and studios like a boss.
                  </li>
                </ul>
              </div>

              <div className={styles.infoItemWrapper}>
                <h2 className={styles.descriptionHeader}>For game developers and publishers</h2>
                <ul className={styles.description}>
                  <li>
                    Level up your revenue stream with our web3 connector through Open Harbor Bridge, connecting your
                    engine straight to the blockchain with minimal investments.
                  </li>
                  <li>
                    Expand your player base and increase revenue with our rental model among the community, where they
                    can rent and play your game.
                  </li>
                  <li>
                    Cash in on the secondary market with tokenized assets, turning your game licenses into transparent
                    web3 tokens for a commission boost.
                  </li>
                  <li>
                    Join forces with thousands of entrepreneurs and transform them into your ambassadors, promoting your
                    game through a new and promising transparent channel.
                  </li>
                  <li>
                    Ditch dependence on a few platforms and keep more of your hard-earned cash with a reduced
                    commission.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
