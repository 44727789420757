import React, { useEffect } from 'react';
import { useState } from 'react';
import styles from './Header.module.scss';

export const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add(styles.disableScroll);
    } else {
      document.body.classList.remove(styles.disableScroll);
    }

    return () => {
      document.body.classList.remove(styles.disableScroll);
    };
  }, [isMenuOpen]);

  const onBurgerClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    if (isMenuOpen) {
      setIsMenuOpen(false);
    }
  };

  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <a href='#' className={styles.logoLink}>
          <img src={process.env.PUBLIC_URL + '/images/header-logo.svg'} alt='Logo' className={styles.logoImg} />
        </a>
        <nav className={`${styles.headerNav} ${isMenuOpen ? styles.openedMenu : ''}`}>
          <ul className={styles.navList}>
            <li className={styles.navListItem}>
              <a href='#about' className={styles.navItemLink} onClick={closeMenu}>
                About
              </a>
            </li>
            <li className={styles.navListItem}>
              <a href='#services' className={styles.navItemLink} onClick={closeMenu}>
                Services
              </a>
            </li>
            <li className={styles.navListItem}>
              <a href='#roadmap' className={styles.navItemLink} onClick={closeMenu}>
                Roadmap
              </a>
            </li>
            <li className={styles.navListItem}>
              <a href='#team' className={styles.navItemLink} onClick={closeMenu}>
                Team
              </a>
            </li>
            <li className={styles.navListItem}>
              <a href='#contacts' className={styles.navItemLink} onClick={closeMenu}>
                Contact us
              </a>
            </li>
          </ul>
        </nav>
        <button onClick={onBurgerClick} className={styles.burgerButton}>
          <div className={`${styles.burgerIcon} ${isMenuOpen ? styles.openedMenu : ''}`}></div>
        </button>
      </div>
    </header>
  );
};
