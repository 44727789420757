import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import { SubscribePage } from '../components/SubscribePage';
import { Header } from '../components/Header';
import { Main } from '../components/Main';
import { Footer } from '../components/Footer';

const routesConfig = [
  {
    path: '/',
    element: (
      <>
        <Header />
        <Main />
        <Footer />
      </>
    ),
  },
  {
    path: '/subscribe',
    element: <SubscribePage />,
  },
];

export const router = createBrowserRouter(routesConfig);
