import React from 'react';
import styles from './BannerSection.module.scss';

export const BannerSection = () => {
  return (
    <section className={styles.bannerSection}>
      <div className={styles.bannerContainer}>
        <div className={styles.bannerHeadingWrapper}></div>
        <div className={styles.bannerOpenButtonWrapper}>
          <button className={styles.bannerOpenButton}>
            <a
              className={styles.bannerOpenLink}
              href={process.env.PUBLIC_URL + '/documents/Open_Harbor_Whitepaper.pdf'}
              target='_blank'
              rel='noreferrer'
            >
              Open whitepaper
            </a>
          </button>
        </div>
      </div>
      <ul className={styles.bannerSocialsList}>
        <li className={styles.bannerSocialsListItem}>
          <a
            href='https://twitter.com/Openharbor_ai
            '
            target='_blank'
            className={`${styles.bannerSocialsItemLink} ${styles.twitter} `}
            rel='noopener noreferrer'
          ></a>
        </li>
        <li className={styles.bannerSocialsListItem}>
          <a
            href='https://discord.gg/zYsxsQdswd'
            target='_blank'
            className={`${styles.bannerSocialsItemLink} ${styles.discord} `}
            rel='noopener noreferrer'
          ></a>
        </li>
        <li className={styles.bannerSocialsListItem}>
          <a
            href='https://t.me/open_harbor'
            target='_blank'
            className={`${styles.bannerSocialsItemLink} ${styles.telegram} `}
            rel='noopener noreferrer'
          ></a>
        </li>
      </ul>
    </section>
  );
};
