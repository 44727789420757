import React from 'react';
import { SectionTitle } from '../../../shared/components';
import styles from './ServicesSection.module.scss';

export const ServicesSection = () => {
  return (
    <section id='services' className={styles.servicesSection}>
      <div className={styles.servicesContainer}>
        <div className={styles.servicesText}>
          <SectionTitle title='Services' />
        </div>
        <div className={styles.servicesBlocks}>
          <div className={`${styles.servicesItem} ${styles.players}`}>
            <div className={`${styles.background} ${styles.playersBackground}`}>
              <h3 className={styles.servicesHeaders}> For players</h3>
              <ul className={styles.list}>
                <li>Buy license as NFT. Connect wallet. Play game.</li>
                <li>We change the rules! The player can buy, sell, rent or burn game licenses.</li>
                <li>Do you want to share the game with friends? Just send NFT.</li>
                <li>The only player creates borders.</li>
              </ul>
            </div>
          </div>

          <div className={`${styles.servicesItem} ${styles.studios}`}>
            <div className={`${styles.background} ${styles.studiosBackground}`}>
              <h3 className={styles.servicesHeaders}> For studios</h3>
              <ul className={styles.list}>
                <li>Join the gaming revolution!</li>
                <li>Create the NFT license in 1 click.</li>
                <li>Integrate into your game with the help of an open-source plugin.</li>
                <li>
                  Get access to a new payable audience, new revenue streams by the secondary market and become the new
                  generation game.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
